import { ErrorPage } from 'components/error/ErrorPage';
import React, { useEffect } from 'react';

import Error404Image from 'assets/images/404-error.png';
import { useTranslation } from 'hooks/useTypedTranslation';
import { logEventInObservability as logEvent } from 'lib/observability';

const Error404 = () => {
  const { t } = useTranslation('common');
  const title = t('404: Page not found') as string;

  useEffect(() => {
    logEvent('404_page', { path: window.location.pathname });
  }, []);

  return (
    <ErrorPage
      image={Error404Image.src}
      title={title}
      text={t("We can't find the page you're looking for, but we can help you get back on track.") as string}
    />
  );
};

export default Error404;
