import React, { Fragment, MouseEvent } from 'react';
import { carryParams } from 'utils';

import { Logo } from 'assets/images';
import { useNavigate } from 'hooks/useNavigate';
import { useTranslation } from 'hooks/useTypedTranslation';
import { useAuthStore } from 'modules/auth/store/authStore';

import HeaderAvatar from '../dashboard/HeaderAvatar';
import Button from '../elements/Button';
import Head from '../elements/Head';
import Meta from '../elements/Meta';

type ErrorPageProps = {
  title: string;
  text: string;
  image: string;
};

export const ErrorPage: React.FC<ErrorPageProps> = props => {
  const { title, text, image } = props;
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const currentUser = useAuthStore(state => state.user);

  const handleBackClick = (event: MouseEvent) => {
    event.preventDefault();

    navigate(carryParams('/'));
  };

  return (
    <Fragment>
      <Head title={title} />
      <Meta />
      <div className="min-h-screen overflow-auto h-full bg-glass-200 text-glass-700">
        <div className="fixed flex justify-between w-full px-5 py-[18px]">
          <div>
            <a className="text-speechify-blue-600 hover:text-speechify-blue-700" href="/" onClick={handleBackClick}>
              <Logo />
            </a>
          </div>
          {currentUser ? (
            <div>
              <HeaderAvatar isInstantListening={false} size={32} />
            </div>
          ) : null}
        </div>
        <div className="flex flex-col h-screen font-ABCDiatype">
          <div className="flex grow flex-col items-center justify-center p-12 text-center">
            <img src={image} alt={title} className={'h-[220px]'} />

            <h1 className="mt-8 font-recoleta font-semibold text-[48px]">{title}</h1>

            <div className="mt-4 text-xl max-w-[500px]">{text}</div>
            <Button className="mt-5 py-3 px-6 w-[320px]" size="lg" onClick={handleBackClick}>
              <span className="text-lg">{currentUser ? t('Back to Library') : t('Back to Sign In')}</span>
            </Button>
          </div>
          <div className="flex justify-center p-4">
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a className="text-electric-400 text-lg font-bold" href="https://help.speechify.com/en/collections/4059654-speechify-for-web" target="_blank">
              {t('View Help Center')}
            </a>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
